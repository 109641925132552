import video1 from '../../assets/video1.mp4'
import video2 from '../../assets/video2.mp4'
import video3 from '../../assets/video3.mp4'
import Carousel from 'react-bootstrap/Carousel';
import './CrouselStyle.css'

function CarouselFadeExample() {
  // return (
  //   <Carousel className='carousel-container' fade>
  //     <Carousel.Item>
  //           <video  autoPlay muted loop>
  //             <source src={video2} type="video/mp4"/>
  //             <source src={video2} type="video/ogg"/>
  //               Your browser does not support the video tag.
  //           </video>
  //     </Carousel.Item>
  //     <Carousel.Item>
  //           <video  autoPlay muted loop>
  //             <source src={video1} type="video/mp4"/>
  //             <source src={video1} type="video/ogg"/>
  //               Your browser does not support the video tag.
  //           </video>
  //     </Carousel.Item>
  //     <Carousel.Item>
  //           <video  autoPlay muted loop>
  //             <source src={video3} type="video/mp4"/>
  //             <source src={video3} type="video/ogg"/>
  //               Your browser does not support the video tag.
  //           </video>
  //     </Carousel.Item>
  //   </Carousel>
  // );
}

export default CarouselFadeExample;